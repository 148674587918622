import { Circle, Flex, Spinner, Text } from '@chakra-ui/react'
import useLocaleString from 'hooks/useLocalString'
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'
import { BiChevronRight } from 'react-icons/bi'

type Props = {
  name: string
  icon: string
  url: string
  offline?: boolean
}

const CoachingTopic = ({ name, icon, url, offline }: Props) => {
  const router = useRouter()

  const [isLoading, setIsLoading] = useState(false)

  // Localized strings
  const nameString = useLocaleString(name)
  const onlineString = useLocaleString('Online')
  const comingSoonString = useLocaleString('Coming soon')

  const handleClick = useCallback(() => {
    if (offline) return
    setIsLoading(true)
    router.push('/topic/' + url, undefined, { shallow: true })
  }, [offline, router, url])

  return (
    <Flex
      bg={offline ? 'transparent' : 'white'}
      px={{ base: 2, md: 3 }}
      py={1}
      border='1px solid'
      borderColor='brandGray.300'
      h='48px'
      align='center'
      gap={2}
      flex={1}
      rounded='md'
      cursor={offline ? 'not-allowed' : 'pointer'}
      _hover={{ base: {}, md: { borderColor: 'gray.200' } }}
      onClick={handleClick}
      data-testid='chat-topic-list-item'
    >
      <Text>{icon}</Text>
      <Text alignSelf='center' flex={1} fontWeight={500}>
        {nameString}
      </Text>
      {!offline && <Circle bg={offline ? 'red.400' : 'green.500'} size='9px' />}
      <Text fontSize='13px' color='brandGray.500'>
        {offline ? comingSoonString : onlineString}
      </Text>
      {!offline && !isLoading && <BiChevronRight />}
      {isLoading && <Spinner size='sm' color='brandGray.500' />}
    </Flex>
  )
}

export default CoachingTopic
