import { Grid, Flex, Text, Box, Image, Divider } from '@chakra-ui/react'
import { kTopics } from 'constants/topics'
import useLocaleString from 'hooks/useLocalString'
import Analytics from 'lib/analytics'
import { kPricePackages } from 'constants/payments'
import { useEffect } from 'react'
import CreditBadge from 'ui/shared/CreditBadge'
import LanguageSelector from 'ui/shared/LanguageSelector'
import { MetaHead } from 'ui/global/Head'
import CreditBox from '../chat/CreditBox'
import CoachingTopic from './CoachingTopic'
import { Outfit } from 'next/font/google'

// Optimized font loading
// https://nextjs.org/docs/basic-features/font-optimization
const outfitFont = Outfit({
  subsets: ['latin'],
})

const HomePage = () => {
  const titleString = useLocaleString(
    'A reliable and unbiased source of guidance and support. Available 24/7, anywhere in the world.'
  )
  const subtitleString = useLocaleString(
    'What would you like support with today?'
  )
  const buyCreditsString = useLocaleString('Buy more credits')

  // Track pageview
  useEffect(() => Analytics.trackEvent('home.view'), [])

  return (
    <>
      <MetaHead />
      <Box w='100%' overflowY='auto' bg='#F9F3F3' flex={1} p={2}>
        <Flex
          align='center'
          direction='column'
          mt={10}
          gap={6}
          mx={{ base: 2, md: 10 }}
        >
          <Flex direction='column' gap={2} align='center'>
            <Image
              src='/images/innermuse-logo.svg'
              w='42px'
              h='42px'
              alt='InnerMuse Logo'
            />
            <Text
              alignSelf='center'
              flex={1}
              fontWeight={600}
              fontSize='30px'
              className={outfitFont.className}
            >
              InnerMuse
            </Text>
          </Flex>
          <Text align='center' fontSize={{ base: '16px', md: '17px' }}>
            {titleString}
          </Text>
        </Flex>
        <Divider my={10} />
        <Text align='center' color='brandGray.500' fontSize='16px'>
          {subtitleString}
        </Text>
        <Grid
          templateColumns='repeat(1, 1fr)'
          gap={1}
          mt={10}
          mx={{ base: 2, md: 10 }}
        >
          {kTopics.map((topic) => (
            <CoachingTopic
              key={topic.id}
              name={topic.name}
              icon={topic.icon}
              url={topic.id}
              offline={!topic.online}
            />
          ))}
        </Grid>
        <Flex
          justify='center'
          align='center'
          mt={7}
          direction='column'
          gap={6}
          mb={3}
        >
          <CreditBadge />
          <Divider />
          <Text color='brandGray.500'>{buyCreditsString}:</Text>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            gap={2}
            align='center'
            justify='center'
            flexWrap={{ base: 'nowrap', md: 'wrap' }}
          >
            {kPricePackages.map((pricePackage) => (
              <CreditBox
                key={pricePackage.id}
                priceId={pricePackage.id}
                credits={pricePackage.credits}
                price={pricePackage.price}
                analyticsSource='home'
              />
            ))}
          </Flex>
          <Divider />
          <LanguageSelector />
        </Flex>
      </Box>
    </>
  )
}

export default HomePage
