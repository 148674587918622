import Layout from 'ui/global/Layout'
import HomePage from 'ui/pages/home'

const Home = () => {
  return (
    <Layout>
      <HomePage />
    </Layout>
  )
}

export default Home
